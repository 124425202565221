<template>
  <v-container fluid>
    <!-- Header -->
    <v-row align="center">
      <v-col>
        <h1 v-if="!isMobile" class="text-h5 font-weight-bold">Eventos</h1>
      </v-col>
      <v-col class=" text-end" v-if="this.$store.state.auth.user.role === 'owner'">
        <v-btn color="primary" @click="dialog = true" v-if="!company.disabled">
          Crear nuevo evento
        </v-btn>
      </v-col>
    </v-row>

    <!-- List -->
    <v-row>
      <v-col>
        <template v-for="(item, index) in events">
          <v-card outlined :key="index" class="mb-3" flat>
            <item-event :item="item" />
          </v-card>
        </template>
      </v-col>
    </v-row>

    <NewEventDialog v-model="dialog" @close="dialog = $event; fecthEvents()" />
  </v-container>
</template>

<script>
import { getEvents } from '@/api/events';
import NewEventDialog from "./Create.vue";
import ItemEvent from "./Item.vue";
import { mapState } from 'vuex';

export default {
  components: { ItemEvent, NewEventDialog },

  data: () => ({
    events: [],
    dialog: false,
    isLoading: false,
  }),

  computed: {
    ...mapState('auth', ['company']),

    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    }
  },

  methods: {
    async fecthEvents() {
      try {
        this.isLoading = true;
        this.events = await getEvents();
      } catch (error) {
        console.error('Error al cargar eventos:', error);
      } finally {
        this.isLoading = false;
      }
    },

    result() {
      console.debug("HOLA")
    }
  },

  mounted() {
    window.scrollTo(0, 0)
    this.fecthEvents();
  },
};
</script>